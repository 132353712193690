<template>
  <div
    class="screensaver"
  >
    <div
      v-if="tilesData"
      class="screensaver__content"
    >
      <div class="screensaver__top-bar" />
      <ScreensaverAnimatedTiles
        class="screensaver__animated-tiles"
        :tiles-data="tilesData"
        :has-video="hasVideo"
        :video-is-playing="videoIsPlaying"
        @show-video="showVideo"
        @hide-video="hideVideo"
      />
      <router-link
        class="screensaver__bottom-button"
        :to="path"
      >
        <img
          class="screensaver__button-image"
          src="../assets/images/hand_animated.webp"
        >
        <Typography
          variant="hint"
          class="screensaver__button-label"
        >
          Tippen & shoppen
        </Typography>
      </router-link>
    </div>
    <router-link
      :to="path"
    >
      <VideoElement
        v-if="videoVisible"
        class="screensaver__video"
        :is-active="videoVisible"
        :src="videoSrc"
        :loop="false"
        @video-ended="videoEndHandler"
      />
    </router-link>
  </div>
</template>

<script>
import {
  shuffle, isEmpty, reverse, flatten, min,
} from 'lodash';
import { mapGetters } from 'vuex';
import ScreensaverAnimatedTiles from '@/components/screensaver-animated-tiles';
import Typography from '@/components/typography';
import VideoElement from '@/components/video-element';

const CATEGORY_TILES_DATA = [
  {
    type: 'screensaverCategoryTile',
    label: 'SmartHome',
    path: '/sub-categories/smarthome',
    imageSrc: '/content/content-images/screensaver/9a767dc75a3ca1d80f97673768249106/gluehbirne_460.png',
  },
  {
    type: 'screensaverCategoryTile',
    label: 'Handys & Smartphones',
    path: '/categories/handys-und-smartphones',
    imageSrc: '/content/content-images/screensaver/08004c4ae9ebcdb67c288b9608fddb68/smartphone_460.png',
  },
  {
    type: 'screensaverCategoryTile',
    label: 'Zubehör',
    path: '/sub-categories/zubehoer',
    imageSrc: '/content/content-images/screensaver/d66546ef81d89fdc40a52ec6295f8c57/zubehoer_460.png',
  },
  {
    type: 'screensaverCategoryTile',
    label: 'Heimvernetzung',
    path: '/sub-categories/heimvernetzung',
    imageSrc: '/content/content-images/screensaver/5cc97fc9a5abe720527dca37756417f8/wlan_mesh_460.png',
  },
  {
    type: 'screensaverCategoryTile',
    label: 'Dein Design',
    path: '/dein-design/dein-design-start-page',
    imageSrc: '/content/content-images/virtual-shelf/clays/schutzhuelle_marmor.png',
  },
  {
    type: 'screensaverCategoryTile',
    label: 'Heatable Capsule Collection',
    path: '/heatable-jacket',
    imageSrc: '/content/content-images/virtual-shelf/clays/heatable-capsule-collection.png',
  },
];

const getRandomNumber = (max) => Math.floor(Math.random() * max);

const getIndexOfK = (arr, k) => {
  for (let i = 0; i < arr.length; i += 1) {
    const index = arr[i].indexOf(k);
    if (index > -1) {
      return [i, index];
    }
  }
  return undefined;
};

export default {
  name: 'Screensaver',
  components: {
    VideoElement,
    ScreensaverAnimatedTiles,
    Typography,
  },
  props: {
    offers: {
      type: Array,
      required: true,
    },
    path: {
      type: String,
      default: '/start',
    },
  },
  data() {
    return {
      tilesData: null,
      getIndexOfK,
      videoVisible: false,
      videoIsPlaying: false,
    };
  },
  computed: {
    ...mapGetters({
      getVideoByKey: 'preloadedVideos/getVideoByKey',
    }),
    videoSrc() {
      return this.getVideoByKey('activationScreen')?.url;
    },
    hasVideo() {
      if (!this.videoSrc) return false;
      return true;
    },
  },
  watch: {
    offers: {
      handler(offers) {
        this.tilesData = this.getTilesData(offers);
      },
      immediate: true,
    },
  },
  methods: {
    showVideo() {
      this.videoVisible = true;
      this.videoIsPlaying = true;
    },
    hideVideo() {
      this.videoVisible = false;
    },
    videoEndHandler() {
      this.videoIsPlaying = false;
    },
    getTilesData(offers) {
      const categoryPairs = [];
      const shuffledCategories = shuffle([...CATEGORY_TILES_DATA]);
      let shuffledOffers = shuffle(shuffledCategories);
      if (!isEmpty(offers)) {
        const clonedOffers = [...offers];
        const sanitizedOffers = clonedOffers.map((offer) => ({
          type: 'screensaverOfferTile',
          label: offer.productName,
          path: offer.path,
          imageSrc: offer.imageSrc,
          defaultPrice: offer.defaultPrice,
          wasPrice: offer.wasPrice,
          highlight: offer.highlight,
          hasEnergyLabel: offer.hasEnergyLabel,
        }));
        shuffledOffers = shuffle(sanitizedOffers);
      }

      let i;
      let n;
      let ctr = 0;
      let category;
      // eslint-disable-next-line no-cond-assign
      for (i = 0; i < 3; i += 1) {
        // eslint-disable-next-line no-cond-assign
        for (n = 0; n < 2; n += 1) {
          category = shuffledCategories[ctr];
          if (isEmpty(categoryPairs[i])) {
            categoryPairs.push([]);
          }
          categoryPairs[i].push({ ...category, side: n });
          ctr += 1;
        }
      }
      // with pairs reversed
      // have to reverse side as well for deep equal recognition
      const reversedCategoryPairs = [...categoryPairs]
        .map((pair) => reverse([...pair])
          .map((entry, index) => ({
            ...entry,
            side: index % 2,
          })));

      const tilesData = [...categoryPairs, ...reversedCategoryPairs];
      // setCategorys end

      // setOffers start
      const loopLength = min([shuffledOffers.length, 6]);
      const deepIndicesOffers = [];

      for (i = 0; i < loopLength; i += 1) {
        const offerEntry = shuffledOffers[i];
        // shuffle for each offer anew
        const furtherShuffledCategories = shuffle([...shuffledCategories]);

        for (n = 0; n < furtherShuffledCategories.length; n += 1) {
          const categoryEntry = furtherShuffledCategories[n];
          const deepIndicesOffersFront = deepIndicesOffers
            .filter((entry) => entry[1] === 0)
            .length;
          const deepIndicesOffersBack = deepIndicesOffers
            .filter((entry) => entry[1] === 1)
            .length;

          // if not yet 6 offers
          if (
            deepIndicesOffers.length < 6
          ) {
            const flattenedTilesData = [...flatten(tilesData)];
            // match chosen category with categorys in tilesData
            // remember: each category is twice in tilesData by default
            // and sort by side so switchingIndex can switch properly
            const matchingCategories = flattenedTilesData
              .filter((dataEntry) => dataEntry.path === categoryEntry.path)
              .sort((a, b) => a.side - b.side);

            // and check that category chosen for switching
            // is still more then once in tilesData
            const timesInTilesData = matchingCategories.length;
            // if category is there more then once
            // -> exchange category for offer
            if (timesInTilesData > 1) {
              let switchingIndex = getRandomNumber(2);
              // if not 3 offers on each side but on one side
              // -> switchingIndex doesn't need to be random
              if (deepIndicesOffersFront === 3) switchingIndex = 1;
              if (deepIndicesOffersBack === 3) switchingIndex = 0;
              // find index of chosen category to switch with
              const deepIndexOfCategory = getIndexOfK(tilesData, matchingCategories[switchingIndex]);
              // switch category with offer
              tilesData[deepIndexOfCategory[0]]
                .splice(deepIndexOfCategory[1], 1, offerEntry);

              deepIndicesOffers.push(deepIndexOfCategory);
              // break out of inner loop and switch to
              // place next offer in parentloop
              break;
            }
          }
        }
      }
      return tilesData;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/style.scss";

.screensaver {
  background-color: #000;
  height: 1920px;
  width: 1080px;
  position: relative;

  &__animated-tiles {
    display: flex;
    flex-wrap: wrap;
    height: calc(1920px - 274px - 116px);
    width: 100%;
  }

  &__top-bar {
    background-color: #2F3234;
    height: 88px;
    width: 100%;
  }

  &__bottom-button {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 274px;
    width: 100%;
    background-color: #E20074;
    border-top: 2px solid #E85CA4;

    &:visited {
      text-decoration: none;
      color: #FFFFFF;
    }
  }

  &__button-label {
    font-weight: 700;
    text-transform: uppercase;
    color: #FFFFFF;
    font-size: 60px;
    letter-spacing: 0.6px;
    line-height: 110px;
    position: absolute;
    bottom: 42px;
    margin-left: 1px;
  }

  &__button-image {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: calc(50% - 63px);
  }

  @keyframes scaleUpAndDisappear {
    0% { transform: translate(-50%, -50%) scale(0); opacity: 1 }
    10% { transform: translate(-50%, -50%) scale(1.3); opacity: 1 }
    20%, 100% { transform: translate(-50%, -50%) scale(1.7); opacity: 0 }
  }

  &__video {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 1080px;
    height: 1920px;
  }
}
</style>
