import axios from 'axios';
import fetchOffersByArticleNumbers from '@/lib/goliath/offers-by-article-numbers';
import { filterPromotionByDate, getFormattedOfferData } from '@/lib/promotions-store-helper';

export default {
  namespaced: true,
  state() {
    return {
      initialized: false,
      promotions: [],
    };
  },
  actions: {
    async loadFromBackend({ commit }) {
      try {
        let offers = [];
        const response = await axios.get('/content/content-json/index.json');
        const { promotions } = response.data;
        const relevantPromotions = promotions.filter(filterPromotionByDate);
        // We do not need to load offers when no active promotions are set!
        if (!relevantPromotions || relevantPromotions.length === 0) return;
        const articleNumbers = relevantPromotions.map((promo) => promo.articleNumber);
        const params = {
          filter: {
            articleNumbers,
            productAvailable: true,
          },
        };
        const goliathOffersData = await fetchOffersByArticleNumbers(params);
        if (goliathOffersData) {
          offers = getFormattedOfferData(relevantPromotions, goliathOffersData.entries);
        }
        commit('SET_PROMOTIONS', offers);
      } catch (exception) {
        console.error('FAILED TO LOAD PROMOTIONS', exception);
        throw exception;
      }
    },
  },
  mutations: {
    SET_PROMOTIONS(state, promotions) {
      state.promotions = promotions;
      state.initialized = true;
    },
  },
};
