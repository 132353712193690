const { spawnSync } = require('child_process');
const Mode = require('../mode');

function detectReleaseFromGit() {
  try {
    const gitRevParse = spawnSync('git', ['rev-parse', 'HEAD']);
    const [release] = gitRevParse.stdout.toString().split('\n');
    return release;
  } catch (e) {
    return undefined;
  }
}

function detectRelease() {
  return (
    process.env.VUE_APP_GIT_COMMIT_HASH
    || detectReleaseFromGit()
    || 'fake0hashb2c7d9e6a31f7e4d8e0f6a3c4b1d5f2e8f9a6c0'
  );
}

const config = {
  disabled: Mode.isDevelopment || Mode.isTest,
  host: 'sentry.dev.i22.de',
  publicKey: 'a26dd26eb5f64b7e9b903e91b30eb7ee',
  remotePath: '/api/103/envelope/',
  // Account related Auth Token. Renew when vincent.bosche's account is suspended in sentry. https://sentry.dev.i22.de/settings/account/api/auth-tokens/.
  authToken: 'b427b8d05b374dc28614e8685d1ef476d187dd521ef741cea92d293365b81329',
  localPath: '/api/sentry', // used for the FE-Proxy
  baseUrl: 'https://a26dd26eb5f64b7e9b903e91b30eb7ee@sentry.dev.i22.de',
  dsn: 'https://a26dd26eb5f64b7e9b903e91b30eb7ee@sentry.dev.i22.de/103',
  fakeDsn: 'https://a26dd26eb5f64b7e9b903e91b30eb7ee@some-invalid-host.i22hosting.de/103',
  options: {
    release: detectRelease(),
    autoBreadcrumbs: true,
    captureUnhandledRejections: true,
    environment: process.env.NODE_ENV,
    sendClientReports: false,
    autoSessionTracking: false, // Send only errors to sentry
    tracesSampleRate: 0.2,
    replaysSessionSampleRate: 0.2,
    replaysOnErrorSampleRate: 1,
  },
};

module.exports = config;
