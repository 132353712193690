<template>
  <div class="dein-design-start-page">
    <Header />
    <DeinDesignLogo
      width="451"
      height="75"
      class="dein-design-start-page__logo"
    />
    <Typography
      ref="heading"
      variant="heading"
      class="dein-design-start-page__heading"
    >
      Individuelle Handyhüllen
    </Typography>
    <Typography
      ref="text"
      variant="title"
      class="dein-design-start-page__text"
    >
      Machen Sie Ihren Smartphoneschutz zum echten Hingucker. Einfach Smartphone
      auswählen, Art der Hülle bestimmen und nach Belieben das Design anpassen.
    </Typography>
    <VideoElement
      v-if="videoSrc"
      :is-active="videoPlaying"
      class="dein-design-start-page__video"
      :src="videoSrc"
      :loop="true"
    />
    <div class="dein-design-start-page__button-container">
      <router-link
        class="dein-design-start-page__button"
        to="/dein-design/select-device-page"
      >
        Los geht's
      </router-link>
    </div>
    <div class="dein-design-start-page__privacy">
      Mit der Nutzung von DeinDesign <br>
      stimme ich den
      <a @click="$refs.dd_privacy.open()">Datenschutzhinweisen</a> zu.
      <DeinDesignPrivacy ref="dd_privacy" />
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/header';
import Footer from '@/components/footer';
import Typography from '@/components/typography';
import DeinDesignLogo from '@/assets/images/deindesign-logo.svg';
import VideoElement from '@/components/video-element';
import DeinDesignBreadcrumb from '@/mixins/dein-design-breadcrumb';
import DeinDesignPrivacy from '@/components/dein-design-privacy';

export default {
  name: 'DeinDesignStartPage',
  components: {
    DeinDesignPrivacy,
    Header,
    Footer,
    Typography,
    DeinDesignLogo,
    VideoElement,
  },
  mixins: [DeinDesignBreadcrumb],
  data() {
    return {
      videoPlaying: false,
    };
  },
  computed: {
    videoSrc() {
      return '/content/content-videos/virtual-shelf/6ed55f5272919c26126845457d51a698/Overview-Film-v04.webm';
    },
  },
  mounted() {
    this.videoPlaying = true;
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/style.scss";

.dein-design-start-page {
  &__logo {
    margin: 60px 30px 24px;
  }
  &__heading {
    display: inline-block;
    padding-left: 30px;
    margin-bottom: 60px;
  }
  &__text {
    display: inline-block;
    padding-left: 30px;
    margin-bottom: 96px;
  }
  &__video {
    margin-bottom: 76px;
    max-height: 858px;
    overflow: hidden;
  }
  &__button-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  &__button {
    color: color(white);
    text-decoration: none;
    // undo button styles
    cursor: pointer;
    text-transform: uppercase;
    display: inline-block;
    padding: 14px 48px;
    font-size: 30px;
    line-height: 35px;
    text-align: justify;
    border-radius: 6px;
    background-color: #e20074;

    &:visited {
      color: color(white);
    }

    &:hover,
    &:active,
    &:focus {
      color: color(white);
    }
  }

  &__privacy {
    text-align: center;
    margin-top: 20px;
  }
}
</style>
