<template>
  <div
    class="image-gallery"
  >
    <div
      ref="imageGalleryStage"
      class="image-gallery__stage"
    >
      <div
        ref="figureGroup"
        class="image-gallery__stage-figure-group"
      >
        <div
          v-for="(image, index) in imageData"
          :key="`image-gallery-figure-${index}`"
          ref="stageFigures"
          class="image-gallery__stage-figure"
        >
          <GoliathImage
            class="image-gallery__figure-image"
            :path="getImagePath(image)"
            :alt="image.name"
            onerror="this.src='/content/content-images/virtual-shelf/ee06ffe64dfe9ca4d20ee50e1e4b241a/missing-image.png'"
          />
        </div>
      </div>
      <Badge
        v-if="rebateDescription"
        class="image-gallery__badge image-gallery__badge--rebate"
        bold
      >
        <span v-html="rebateDescription" />
        <button
          v-if="footnoteText"
          ref="footnoteButton"
          class="btn badge__footnote image-gallery__footnote-button"
          @click.prevent="$refs.modal.open"
        >
          *
        </button>
        <Modal
          ref="modal"
        >
          <div v-html="footnoteText" />
        </Modal>
      </Badge>
      <Badge
        v-else-if="showDiscount"
        class="image-gallery__badge image-gallery__badge--large"
        bold
      >
        %
      </Badge>
      <Badge
        v-else-if="showCustomizableBadge"
        class="image-gallery__badge image-gallery__badge--customizable"
        bold
      >
        Selbst<br>gestalten!
      </Badge>
    </div>
    <ul
      v-if="hasMultipleImages"
      class="image-gallery__overview"
    >
      <li
        v-for="(image, index) in imageData"
        :key="`image-gallery-selector-${index}`"
        class="image-gallery__selector"
        :class="activeClass(index)"
        @click="slideTo(index)"
      />
    </ul>
    <div class="image-gallery__board" />
  </div>
</template>

<script>
import { gsap } from 'gsap';
import { Draggable } from 'gsap/all';
import { get } from 'lodash';
import GoliathImage from '@/components/goliath-image';
import Modal from '@/components/modal';

import { Badge } from '@i22-td-smarthome/component-library';

gsap.registerPlugin(Draggable);

export default {
  name: 'ImageGallery',
  components: {
    GoliathImage,
    Badge,
    Modal,
  },
  props: {
    imageData: {
      type: Array,
      required: true,
    },
    showDiscount: {
      type: Boolean,
      default: false,
    },
    rebate: {
      type: Object,
      default: null,
    },
    showCustomizableBadge: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      timeline: gsap.timeline(),
      viewedImageIndex: 0,
      draggableInstance: null,
    };
  },
  computed: {
    hasDraggableInstance() {
      return this.draggableInstance && this.draggableInstance[0];
    },
    hasMultipleImages() {
      return this.imageData.length > 1;
    },
    maxIndex() {
      return this.imageData.length;
    },
    rebateDescription() {
      if (!this.rebate) return null;
      const { description } = this.rebate;
      if (description === 'no description') return null;
      return description;
    },
    footnoteText() {
      const footnote = get(this.rebate, 'footnote');
      if (!footnote) return null;
      return footnote;
    },
    figureWidth() {
      const figures = this.$refs.stageFigures;
      if (figures === undefined) {
        return undefined;
      }
      return figures[0].clientWidth;
    },
    figureLength() {
      const figures = this.$refs.stageFigures;
      if (figures === undefined) {
        return 0;
        // throw new Error('Unable to calculate figure width as no images were specified');
      }
      return figures.length;
    },
    snapArray() {
      const snapArray = [];
      // calculates snap coordinate
      const { figureWidth } = this;

      if (!figureWidth) return snapArray;

      for (let i = 0; i < this.figureLength; i += 1) {
        snapArray.push(-i * figureWidth);
      }
      return snapArray;
    },
  },
  watch: {
    $route() {
      this.slideTo(0);
    },
  },
  mounted() {
    this.createDraggable();
  },
  beforeDestroy() {
    if (this.hasDraggableInstance) {
      this.draggableInstance[0].kill();
    }
  },
  methods: {
    setViewedImage() {
      const { figureGroup } = this.$refs;
      const computedStyle = getComputedStyle(figureGroup).transform;
      const regex = /matrix\(1, 0, 0, 1, (.+), 0\)/;
      const value = computedStyle.match(regex)[1];
      const newIndex = (value / this.figureWidth) * -1;
      this.viewedImageIndex = Math.max(
        0, Math.min(this.figureLength, Math.round(newIndex)),
      );
    },
    activeClass(index) {
      return index === this.viewedImageIndex ? 'image-gallery__selector--active' : '';
    },
    slideTo(index) {
      this.viewedImageIndex = index;
      const { figureWidth } = this;
      const { figureGroup } = this.$refs;
      // slide animation for figureGroup to the computed x coordinate with an animation duration of 1 second.
      this.timeline.to(figureGroup, {
        x: -figureWidth * index,
        duration: 1,
      });
    },
    setActiveIndex(computedIndex) {
      // handles edge cases, when computedIndex is beyond imageData.length
      const activeIndex = Math.max(0, Math.min(computedIndex, this.maxIndex - 1));

      this.viewedImageIndex = activeIndex;
    },
    setBounds() {
      this.draggableInstance[0].applyBounds({
        minX: 0,
        maxX: (this.$refs.figureGroup.scrollWidth * -1) + this.$refs.imageGalleryStage.clientWidth,
      });
    },
    createDraggable() {
      this.draggableInstance = Draggable.create(
        '.image-gallery__stage-figure-group', {
          type: 'x',
          edgeResistance: 0.7,
          onPress: () => {
            this.setBounds();
          },
          bounds: {
            target: '.image-gallery__stage',
          },
          inertia: true,
          zIndexBoost: false,
          snap: this.snapArray,
          onThrowUpdate: () => this.setViewedImage(),
        },
      );
    },
    getImagePath(image) {
      if (image.path) return image.path;
      if (image.url) return image.url;
      return null;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/style.scss";

.image-gallery {
  height: 600px;
  position: relative;

  &__stage {
    height: 600px;
    margin: 0 auto;
    overflow: hidden;
    width: 100%;
  }

  &__stage-figure-group {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 100%;
  }

  &__stage-figure {
    align-items: flex-end;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    padding-bottom: 120px;
    position: relative;
    width: 100%;

    &::after {
      border-radius: 100%;
      bottom: 52px;
      box-shadow: 0px 50px 20px 10px rgba(0, 0, 0, 0.15);
      content: " ";
      height: 9px;
      left: 50px;
      position: absolute;
      right: 50px;
      transform: translateX(100%) scaleY(0.3);
      width: 30%;
      z-index: 20;
    }
  }

  &__figure-image {
    max-height: 456px;
    position: relative;
  }

   &__overview {
    display: flex;
    flex-wrap: nowrap;
    left: 50%;
    margin-bottom: 10px;
    position: absolute;
    top: 498px;
    transform: translateX(-50%);
    z-index: 5;
  }

  &__badge {
    width: 140px;
    display: flex;
    justify-content: center;
    bottom: 120px;
    left: 254px;
    z-index: 10;

    &--rebate {
      @include font-size(24px, 27px);
    }

    &--large {
      @include font-size(80px, 80px);
    }

    &--customizable {
      bottom: auto;
      top: 0;
      width: auto;
      @include font-size(28px, 30px);
      text-transform: uppercase;
    }
  }

  &__selector {
    border-bottom: 2px solid  #DFDFDF;
    list-style: none;
    margin: 1px 9px;
    padding: 10px;
    width: 40px;

    &--active {
      border-bottom: 4px solid  #E20074;
      margin-bottom: 0;
      margin-top: 0;
    }
  }

  &__board {
    background-clip: padding-box, content-box;
    background-color: transparent;
    background-image: url('../assets/images/detail-page-shelf-beginning.png'),
                      url('../assets/images/alternative-shelf-main.png');
    background-position: left top, left top;
    background-repeat: no-repeat, repeat-x;
    background-size: 100px 100%, auto 100%;
    height: 135px;
    margin: -60px 49px 0;
    padding: 0 100px;
    position: relative;
    z-index: -1;

    &::after {
      background-color: transparent;
      background-image: url('../assets/images/detail-page-shelf-beginning.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      content: " ";
      display: block;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      transform: scaleX(-1);
      width: 100px;
    }
  }
}

</style>
